import { coeffect, effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { globals, http } from 'reffects-batteries';
import { registerAudienceSegmentationEvents } from 'components/UnifiedCampaignForm/AudienceSegmentationForm/events';
import { registerBudgetFormEvents } from 'components/UnifiedCampaignForm/BudgetForm/events';
import {
  fixTwoDecimalsFor,
  fixThreeDecimalsFor,
} from 'components/UnifiedCampaignForm/fixTwoDecimalsFor';
import { getCpcRecommendation } from '../../Models/CpcRecommendation';
import registerTargetingFormEvents from './TargetingForm/events';
import registerBiddingFormEvents from './BiddingForm/events';
import { dateAsISOString } from '../../utils/dates';
import { AVAILABLE_CRITERIA_ORDERED } from './constants';
import { ACCELERATED_DELIVERY } from './DeliveryForm/constants';

function formatCampaignNameForUtm(campaignName) {
  return campaignName
    .toLowerCase()
    .replace(/&+/g, ' ')
    .trim()
    .replace(/\s+/g, '-');
}

function updateUtmCampaign(currentTrackingParams, campaignName) {
  return currentTrackingParams
    .split('&')
    .map((param) => {
      const [key] = param.split('=');
      if (key === 'utm_campaign') {
        return [key, formatCampaignNameForUtm(campaignName)].join('=');
      }
      return param;
    })
    .join('&');
}

function getBudgetAmountRecommendation(features, minMonthlyBudget) {
  return 3 * minMonthlyBudget;
}
function isNotNewCampaign(originalFormData) {
  return originalFormData.id;
}

function getRecommendedCpc(features, globalsCoEffects) {
  return getCpcRecommendation(globalsCoEffects.trovitData);
}

export default function registerEvents() {
  registerEventHandler(
    'INITIALIZE_CAMPAIGN_FORM',
    function initializeForm({
      globals: globalsCoEffects,
      currentDateTime: currentTimeInMillis,
    }) {
      const {
        trovitData: {
          minimum: { minCpcPremium, minMonthlyBudget, minDailyBudget },
          features,
          currencySymbol,
          rights,
          availableSegmentationCriteria,
          isBillingSourceValid,
          isDefaulter,
        },
      } = globalsCoEffects;
      const todayAsString = dateAsISOString(new Date(currentTimeInMillis));
      const fixedTwoDecimalsRecommendedCpc = fixTwoDecimalsFor(
        getRecommendedCpc(features, globalsCoEffects)
      );
      const fixedThreeDecimalsRecommendedCpc = fixThreeDecimalsFor(
        getRecommendedCpc(features, globalsCoEffects)
      );
      return {
        ...state.set({
          'formData.endDateAsString': '',
          'formData.cpc': fixedTwoDecimalsRecommendedCpc,
          'formData.cpcMobile': fixedTwoDecimalsRecommendedCpc,
          'formData.cps': fixedTwoDecimalsRecommendedCpc,
          'formData.cpsMobile': fixedTwoDecimalsRecommendedCpc,
          'formData.cpcMil': fixedThreeDecimalsRecommendedCpc,
          'formData.cpcMilMobile': fixedThreeDecimalsRecommendedCpc,
          'formData.budgetAmount': getBudgetAmountRecommendation(
            features,
            minMonthlyBudget
          ),
          'formData.deliveryMethod': ACCELERATED_DELIVERY,
          'formData.startDateAsString': todayAsString,
          'formData.desktopTrackingParameters':
            'utm_source=Lifull-connect&utm_medium=CPC&utm_campaign=',
          'formData.mobileTrackingParameters':
            'utm_source=Lifull-connect&utm_medium=CPC&utm_campaign=',
          'formData.userSegmentation': [],
          'formData.objective': 'clicks',
          'formData.targetCPA': '0.0',
          minCpcPremium,
          minMonthlyBudget,
          minDailyBudget,
          minStartDateAsString: todayAsString,
          currencySymbol,
          rights,
          availableSegmentationCriteria:
            decorateAndRemoveInvalidSegmentationCriteria(
              availableSegmentationCriteria,
              AVAILABLE_CRITERIA_ORDERED
            ),
          hasBillingData: isBillingSourceValid,
          isDefaulter,
          todayAsString,
        }),
      };
    },
    [globals.get('trovitData'), coeffect('currentDateTime')]
  );

  registerEventHandler(
    'UPDATE_CAMPAIGN_FORM',
    function updateCampaignEditFormData({ state: { formData } }, newFormData) {
      const normalizedData = { ...newFormData };
      if (typeof normalizedData.budgetAmount === 'string') {
        normalizedData.budgetAmount = parseInt(normalizedData.budgetAmount, 10);
      }

      return state.set({
        formData: { ...formData, ...normalizedData },
      });
    },
    [
      state.get({
        formData: 'formData',
      }),
    ]
  );

  registerEventHandler(
    'SUBMIT_CAMPAIGN_FORM',
    function submitCampaignEditForm(coeffects) {
      const {
        state: { formData, isSpecificTargeting, originalFormData },
        globals: {
          trovitData: {
            source: { countryId },
          },
        },
      } = coeffects;
      const body = mapFormRequestData(originalFormData, formData);
      if (!isSpecificTargeting) {
        body.segmentation = [];
      }
      let url;
      let httpMethodEffect;
      if (isNotNewCampaign(originalFormData)) {
        url = `/index.php/campaigns/${countryId}_${originalFormData.id}`;
        httpMethodEffect = http.put;
      } else {
        url = '/index.php/cod.campaigns_save';
        httpMethodEffect = http.post;
      }

      return {
        ...state.set({
          isSubmitting: true,
        }),
        ...httpMethodEffect({
          body,
          url,
          successEvent: ['SUBMIT_CAMPAIGN_FORM_SUCCESS'],
          errorEvent: ['SUBMIT_CAMPAIGN_FORM_ERROR'],
        }),
      };
    },
    [
      state.get({
        formData: 'formData',
        originalFormData: 'originalFormData',
        isSpecificTargeting: 'isSpecificTargeting',
      }),
      globals.get('trovitData'),
    ]
  );

  registerEventHandler(
    'SUBMIT_CAMPAIGN_FORM_SUCCESS',
    function submitCampaignEditFormSuccess({ locationOrigin }, [response]) {
      if (returnedErroneousResponse(response)) {
        // eslint-disable-next-line no-unused-expressions
        trovitApp.ajax && trovitApp.ajax.showResponse(response);
        window.scroll(0, 0);
        return {
          ...state.set({
            isSubmitting: false,
          }),
        };
      }

      return {
        redirectToUrl: `${locationOrigin}/index.php/cod.campaigns`,
      };
    },
    [coeffect('locationOrigin')]
  );

  registerEventHandler(
    'SUBMIT_CAMPAIGN_FORM_ERROR',
    function submitCampaignFormError(_, [response]) {
      if (returnedErroneousResponse(response)) {
        window.scroll(0, 0);
        // eslint-disable-next-line no-unused-expressions
        trovitApp.ajax && trovitApp.ajax.showResponse(response);
      }
      return {
        ...state.set({
          isSubmitting: false,
        }),
      };
    }
  );

  registerEventHandler(
    'UPDATE_TRACKING_PARAMETERS',
    function updateTrackingParameters(
      {
        state: {
          hasTrackingBannerBeenOpened,
          currentMobileTrackingParameters,
          currentDesktopTrackingParameters,
        },
      },
      { campaignName }
    ) {
      const desktopParameters = updateUtmCampaign(
        currentDesktopTrackingParameters,
        campaignName
      );
      const mobileParameters = updateUtmCampaign(
        currentMobileTrackingParameters,
        campaignName
      );

      let showBannerEffect = {};
      if (!hasTrackingBannerBeenOpened) {
        showBannerEffect = {
          dispatch: {
            id: 'SHOW_UPDATED_TRACKING_PARAMETERS_BANNER',
          },
        };
      }

      return {
        ...showBannerEffect,
        ...state.set({
          'formData.desktopTrackingParameters': desktopParameters,
          'formData.mobileTrackingParameters': mobileParameters,
        }),
      };
    },
    [
      state.get({
        currentMobileTrackingParameters: 'formData.mobileTrackingParameters',
        currentDesktopTrackingParameters: 'formData.desktopTrackingParameters',
        hasTrackingBannerBeenOpened: 'hasTrackingBannerBeenOpened',
      }),
    ]
  );

  registerEventHandler(
    'SHOW_UPDATED_TRACKING_PARAMETERS_BANNER',
    function showUpdatedTrackingParametersBanner() {
      return {
        ...state.set({
          isTrackingBannerOpen: true,
          hasTrackingBannerBeenOpened: true,
        }),
        dispatchLater: {
          id: 'HIDE_UPDATED_TRACKING_PARAMETERS_BANNER',
          milliseconds: 10000,
        },
      };
    }
  );

  registerEventHandler(
    'HIDE_UPDATED_TRACKING_PARAMETERS_BANNER',
    function showUpdatedTrackingParametersBanner() {
      return state.set({
        isTrackingBannerOpen: false,
      });
    }
  );

  registerTargetingFormEvents();
  registerAudienceSegmentationEvents();
  registerBiddingFormEvents();
  registerBudgetFormEvents();
}

function mapFormRequestData(originalFormData, formData) {
  return {
    ...originalFormData,
    isUnified: true,
    cpc: Number(formData.cpc),
    cpcMobile: Number(formData.cpcMobile) || Number(formData.cpc),
    cps: Number(formData.cps),
    cpsMobile: Number(formData.cpsMobile) || Number(formData.cps),
    endDate: formData.endDateAsString,
    name: formData.campaignName,
    startDate: formData.startDateAsString,
    tracking_mobile:
      formData.objective !== 'leads' ? formData.mobileTrackingParameters : '',
    tracking_desktop:
      formData.objective !== 'leads' ? formData.desktopTrackingParameters : '',
    budget: {
      ...originalFormData.budget,
      amount: Number(formData.budgetAmount),
      isDaily: formData.isDaily,
      timeFrame: formData.isDaily ? 'P1D' : 'P1M',
      deliveryMethod: formData.deliveryMethod,
    },
    segmentation: buildSegmentationRequestData(formData),
    userSegmentation: formData.userSegmentation,
    objective: formData.objective,
    targetCPA: formData.targetCPA,
    cleanTrafficDetailPageEnabled: formData.cleanTrafficDetailPageEnabled,
  };
}

function buildSegmentationRequestData(formData) {
  return Object.entries(formData.segmentation)
    .filter(
      ([, segmentation]) =>
        segmentation?.value?.length > 0 ||
        segmentation?.from ||
        segmentation?.to
    )
    .map(([field, segmentation]) => {
      if (field === 'price') {
        return {
          field,
          operation: '<>',
          value: [segmentation.from, segmentation.to],
        };
      }
      return {
        field,
        operation: segmentation.operation,
        value: segmentation.value,
      };
    });
}

function returnedErroneousResponse(response) {
  return response.data == null || response.success === false;
}

function decorateAndRemoveInvalidSegmentationCriteria(
  segmentationCriteria,
  criteriaOrdered
) {
  return criteriaOrdered
    .map((criteria) => {
      if (segmentationCriteria[criteria]) {
        return {
          field: criteria,
          ...segmentationCriteria[criteria],
        };
      }

      return null;
    })
    .filter((criteria) => criteria != null);
}

registerEventHandler(
  'VALIDATE_AND_SUBMIT_CAMPAIGN_FORM',
  function validateAndSubmitCampaignForm(_, { element }) {
    const isValid = element.reportValidity();

    const effect = {
      ...state.set({ isValidated: isValid }),
    };

    if (isValid) {
      effect.dispatch = { id: 'SUBMIT_CAMPAIGN_FORM' };
    }

    return effect;
  }
);

registerEventHandler(
  'INITIALIZE_SUMMARY_CAMPAIGN_MATCHING_ADS',
  function initializeSummaryCampaignMatchingAds() {
    return effects.dispatch('REQUEST_SEGMENTATION_MATCHING_ADS', {});
  }
);

registerEventHandler('GO_BACK_CLICKED', function goBackClicked() {
  return state.set({
    openUnifiedForm: false,
  });
});
