import CurrencyInput from 'components/library/Input/CurrencyInput/CurrencyInput';
import React from 'react';
import { subscribe } from 'reffects-store';
import { cpcCompetitivenessIndicatorSelector } from 'components/UnifiedCampaignForm/BiddingForm/selectors';
import { dispatch } from 'reffects';
import {
  currencySymbolSelector,
  formDataSelector,
  minCpcPremiumSelector,
} from 'components/UnifiedCampaignForm/selectors';
import MessageCard from 'components/library/MessageCard/MessageCard';
import CpcThreeDecimalsMobileForm from 'components/UnifiedCampaignForm/BiddingForm/partials/CpcThribeeDecimalsMobileForm';

function CpcThreeDecimalsForm({
  currencySymbol,
  minCpcPremium,
  onCpcInputChange,
  formData,
  formatDecimalOnInputBlur,
}) {
  const { cpcMil } = formData;
  return (
    <>
      <CurrencyInput
        name="cpcMil"
        label={trovitApp.i18n('create_campaign_bidding_cpc_label')}
        value={cpcMil}
        symbol={currencySymbol}
        min={minCpcPremium}
        step={0.001}
        required
        onChange={onCpcInputChange}
        onBlur={formatDecimalOnInputBlur}
      />
      <MessageCard className="ThreeDecimals__warning">
        {trovitApp.i18n(
          'create_campaign_bidding_strategy_three_decimals_warning_message'
        )}
      </MessageCard>

      <CpcThreeDecimalsMobileForm />
    </>
  );
}

export default subscribe(
  CpcThreeDecimalsForm,
  function mapStateToProps(state) {
    return {
      minCpcPremium: minCpcPremiumSelector(state),
      formData: formDataSelector(state),
      cpcCompetitivenessIndicator: cpcCompetitivenessIndicatorSelector(
        state,
        trovitData.competenceLimits
      ),
      currencySymbol: currencySymbolSelector(state),
    };
  },
  {
    onCpcInputChange(e) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_CPC_MIL',
        payload: {
          [e.target.name]: e.target.value,
        },
      });
    },
    formatDecimalOnInputBlur(e) {
      dispatch({
        id: 'FORMAT_INPUT_DECIMALS_MIL',
        payload: {
          name: e.target.name,
          value: e.target.value,
        },
      });
    },
  }
);
