import Card from 'components/library/Card/Card';
import Header5 from 'components/library/Header5/Header5';
import React from 'react';
import { subscribe } from 'reffects-store';
import {
  isEnableThreeDecimalsFeature,
  showCardSelector,
} from 'components/UnifiedCampaignForm/selectors';
import HelpIconMaterial from 'components/library/Icons/HelpIconMaterial';
import Tooltip from 'components/library/Tooltip/Tooltip';
import CpcForm from 'components/UnifiedCampaignForm/BiddingForm/partials/CpcForm';
import BidStrategyNote from 'components/UnifiedCampaignForm/BiddingForm/partials/BidStrategyNote';
import CpcThreeDecimalsForm from 'components/UnifiedCampaignForm/BiddingForm/partials/CpcThreeDecimalsForm';

function BidStrategySidebar() {
  return (
    <div className="CampaignForm__cardWrapperTitle">
      <Header5>
        {trovitApp.i18n('create_campaign_bidding_strategy_title')}
        <Tooltip
          content={
            <>
              {trovitApp.i18n('campaign_type_tooltip')}
              <br />
              <a
                className="learn-more"
                target="_blank"
                rel="noopener noreferrer"
                href="https://help.thribee.com/hc/en-us/articles/360006855414-Define-CPC"
              >
                {trovitApp.i18n('learn_more_help_center')}
              </a>
            </>
          }
          placement="right"
        >
          <HelpIconMaterial />
        </Tooltip>
      </Header5>
    </div>
  );
}
export function BiddingForm({ showCard, isFeatureEnabled }) {
  return (
    showCard && (
      <Card>
        <div className="CampaignForm__cardWrapper CampaignForm__cardWrapper--threeColumns">
          <div className="CampaignForm__cardWrapperTitle">
            <BidStrategySidebar />
          </div>
          <div className="CampaignForm__cardWrapperInput">
            {isFeatureEnabled ? <CpcThreeDecimalsForm /> : <CpcForm />}
          </div>
          <div className="CampaignForm__cardWrapper__note">
            <BidStrategyNote />
          </div>
        </div>
      </Card>
    )
  );
}

export default subscribe(BiddingForm, function mapStateToProps(state) {
  return {
    showCard: showCardSelector(state),
    isFeatureEnabled: isEnableThreeDecimalsFeature(state),
  };
});
