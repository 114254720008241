import React from 'react';
import './MessageCard.css';

export default function MessageCard({ className, children }) {
  return (
    <div
      className={`MessageCard ${className}`}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
}
