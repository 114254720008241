import React from 'react';
import { subscribe } from 'reffects-store';
import {
  currencySymbolSelector,
  formDataSelector,
  minCpsSelector,
} from 'components/UnifiedCampaignForm/selectors';
import { dispatch } from 'reffects';
import CollapsibleLink from '../../../library/CollapsibleLink/CollapsibleLink';
import CurrencyInput from '../../../library/Input/CurrencyInput/CurrencyInput';
import Radio from '../../../library/Input/Radio/Radio';

function CpcThreeDecimalsMobileForm({
  isCustomCpcMobile,
  showCpcAdvancedOptions,
  formData,
  currencySymbol,
  minCpcPremium,
  setCustomCpcMobile,
  onInputChange,
  onBlur,
}) {
  const { cpcMilMobile } = formData;
  return (
    <CollapsibleLink
      text={trovitApp.i18n('g_advanced_options')}
      collapsed={!showCpcAdvancedOptions}
    >
      <Radio
        id="radio-cpc-mobile-same"
        value="cpc-mobile-same"
        checked={!isCustomCpcMobile}
        name="radio-group-cpc-mobile"
        onChange={() => setCustomCpcMobile(false)}
      >
        {trovitApp.i18n('campaign_same_cpc_for_mobile')}
      </Radio>
      <Radio
        id="radio-cpc-mobile-custom"
        value="cpc-mobile-custom"
        name="radio-group-cpc-mobile"
        checked={isCustomCpcMobile}
        onChange={() => setCustomCpcMobile(true)}
      >
        {trovitApp.i18n('campaign_custom_cpc_for_mobile')}
      </Radio>
      {isCustomCpcMobile ? (
        <CurrencyInput
          className="CampaignForm__cpc-mobile"
          name="cpcMilMobile"
          label={trovitApp.i18n('cpc_mobile_thribee')}
          value={cpcMilMobile}
          symbol={currencySymbol}
          min={minCpcPremium}
          step={0.001}
          required
          onChange={onInputChange}
          onBlur={onBlur}
        />
      ) : null}
    </CollapsibleLink>
  );
}

export default subscribe(
  CpcThreeDecimalsMobileForm,
  function mapStateToProps(state) {
    return {
      isCustomCpcMobile: state.isCustomCpcMobile,
      showCpcAdvancedOptions: state.showCpcAdvancedOptions,
      formData: formDataSelector(state),
      currencySymbol: currencySymbolSelector(state),
      minCpcPremium: minCpsSelector(state),
    };
  },
  {
    onInputChange(e) {
      dispatch({
        id: 'UPDATE_CAMPAIGN_FORM',
        payload: {
          [e.target.name]: e.target.value,
        },
      });
    },
    formatDecimalOnInputBlur(e) {
      dispatch({
        id: 'FORMAT_INPUT_DECIMALS_MIL',
        payload: {
          name: e.target.name,
          value: e.target.value,
        },
      });
    },
    setCustomCpcMobile(active) {
      dispatch({
        id: 'SET_CUSTOM_CPC_MOBILE_OPTION_MIL',
        payload: { isCustomCpcMobile: active },
      });
    },
  }
);
